import './complex-search.scss';
import Search from "../Search/Search";
import {ChangeEvent} from "react";
import {ISortingOption} from "../SearchAndSort/SearchAndSort";
import Sorting from "../SearchAndSort/Sorting/Sorting";
import Filter, {FilterOption} from "../Filter/Filter";
import Toggle from "../Toggle/Toggle";

interface ComplexSearchProps {
  search?: {
    value: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
  },
  sorting?: {
    options: ISortingOption[],
    value: ISortingOption,
    onChange: (newSorting: ISortingOption) => void
  },
  filter?: {
    name: string
    value: FilterOption[],
    options: FilterOption[],
    onChange: (newFilters: FilterOption[]) => void
  },
  toggle?: {
    active: boolean,
    onToggle: () => void,
    label?: string
  }[]
}

const ComplexSearch = (props: ComplexSearchProps) => {
  const {
    search,
    sorting,
    filter,
    toggle
  } = props;

  return <div className={'complex-search'}>

    {
      search
        ? <span
          className={'complex-search-search-container'}
          //style={{width: `calc(100% - 16px - ${filter ? '261px' : '0px'} - ${sorting ? '389px' : '0px'} - ${toggle ? '261px' : '0px'})`}}
        >
      <Search
        value={search.value}
        onChange={search.onChange}
        placeholder={search.placeholder}
      />
    </span>
        : null
    }

    {
      filter
        ? <Filter
          name={filter.name}
          value={filter.value}
          options={filter.options}
          onChange={filter.onChange}
        />
        : null
    }

    {
      sorting
        ? <Sorting
          sortingOptions={sorting.options}
          activeSorting={sorting.value}
          onSortingChange={sorting.onChange}
        />
        : null
    }

    {
      toggle
        ? toggle.map((item, index) =>
          <span key={index}>
            <Toggle
              active={item.active}
              onToggle={item.onToggle}
              label={item.label}
            />
          </span>)
        : null
    }

  </div>
}

export default ComplexSearch;