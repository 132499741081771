import './footer.scss';
import KMQLogo from '../../assets/logo/LogoWhite.svg';
import moment from "moment";
import {TERMS_OF_USE_URL} from "../Router/TextWithLink/TermsOfUse";
import {PRIVACY_POLICY_URL} from "../Router/TextWithLink/PrivacyPolicy";

const Footer = () => {
  return <div className={'footer'}>
    <div className={'footer-container'}>
      <div>
        <img
          src={KMQLogo}
          alt={'logo'}
          className={'footer-logo'}
        />

        <div className={'footer-links'}>
          <div className={'footer-links-title'}>
            About
          </div>
          <div className={'footer-links-text'}
               onClick={() => window.open(TERMS_OF_USE_URL, '_blank')}>
            Terms & Conditions
          </div>
          <div className={'footer-links-text'}
               onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
            Privacy Policy
          </div>
        </div>

        <div className={'footer-contact'}>
          <div className={'footer-contact-title'}>
            Contact
          </div>
          <div className={'footer-contact-text'}>
            hello@knowmeq.com
          </div>

        </div>
      </div>

      <div className={'footer-copyright'}>
        (C) KnowMeQ {moment().format('YYYY')}
      </div>

    </div>
  </div>
}

export default Footer;