import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {ISortingOption} from "../../../components/UI/SearchAndSort/SearchAndSort";
import {programsSortingOptions} from "../../../constants/ui/sortingOptions";
import {IProgram} from "../../../constants/types/IProgram";
import PlarCard, {PlarCardType} from "../../../components/UI/Cards/Plar/PlarCard";
import Loading from "../../../components/UI/Loading/Loading";
import ComplexSearch from "../../../components/UI/ComplexSearch/ComplexSearch";
import {useMutateCourseFeedback} from "../../../api/cpl-applicant/useMutateFavourite";
import PortfolioStatusButtons from "../../../components/Payment/SubmitPaymentButton";
import PortfolioChiclet from "../../../components/UI/Chiclets/PortfolioChiclet/PortfolioChiclet";
import Pagination from "../../assessments/components/EmployeeTablePagination/Pagination";
import Notification from "../../../components/UI/Notification/Notification";
import {UserContext} from "../../../hooks/Context/UserContext";
import UserProgramsEmpty from "./UserNoPrograms";
import {usePrograms} from "../../../api/api-hooks/usePrograms";

const UserPrograms = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [portfolio] = useContext(PortfolioContext);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(programsSortingOptions[2]);

  const {data: programsData} = usePrograms({
    searchValue: searchValue,
    sortingValue: sortingValue?.value,
    pageState: [currPage, setCurrPage],
    portfolio_id: portfolio.id
  });

  const {isPending: isLoadingFavourite, mutate: postFavourite} = useMutateCourseFeedback({
    userIsSoft: user.isSoft
  });

  const getCardInfo = (program: IProgram) => {
    if (program.num_courses_not_reviewed === program.num_courses_recommended) {
      return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
    }
    if (program.num_courses_not_reviewed === 0) {
      return {type: PlarCardType.Not_started, chicletText: 'Review complete'};
    }
    return {type: PlarCardType.Not_started, chicletText: 'Review in progress'};
  }

  const handleUpdateFavourite = (programId: string) => {
    let currProgram = programsData.programs.find((item: IProgram) => String(item.id) === String(programId));
    if (currProgram) {
      postFavourite({
        "portfolio_id": portfolio.id,
        "program_id": programId,
        "create_favourite": !currProgram.favourite
      });
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  if (programsData?.no_recommendations_message) {
    return <UserProgramsEmpty text={programsData.no_recommendations_message}/>
  }

  return <div>
    <Loading loading={!programsData}/>
    <Notification>
      More details and assessments may be requested to further confirm your eligibility.
    </Notification>
    <div className={`user-programs ${(isLoadingFavourite) ? 'user-programs-loading' : ''}`}>
      <div>
        <PortfolioChiclet status={portfolio.status}/>
      </div>

      <div className={'user-programs-title'}>
        My Programs
      </div>
      <div className={'user-programs-text'}>
        Here, you can explore a list of eligible programs based on the information you have provided. We recommend
        favouring your top programs to demonstrate your preferred outcome.
      </div>

      <PortfolioStatusButtons/>

      <ComplexSearch
        search={{
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value),
          placeholder: 'Search Programs'
        }}
        sorting={{
          options: programsSortingOptions,
          value: sortingValue,
          onChange: (newSorting) => setSortingValue(newSorting)
        }}
      />

      {
        programsData?.programs?.length
          ? programsData.programs.map((item: IProgram, index: number) =>
            <div key={index}>
              <PlarCard
                {...getCardInfo(item)}
                title={item.name}
                subtitle={`${item.num_cpl_eligible_recommendations} out of ${item.num_courses_recommended} course${item.num_cpl_eligible_recommendations > 1 ? 's are' : ' is'} eligible`}
                cartNumber={`${item.num_courses_in_cart} course${item.num_courses_in_cart !== 1 ? 's' : ''} in cart`}
                onClick={() => navigate(`/cpl-applicant/my-programs/${item.id}`)}
                buttonText={'View recommended courses'}
                statusText={item.num_courses_not_reviewed === 0
                  ? [`${item.num_courses_recommended} course${item.num_courses_recommended > 1 ? 's' : ''} approved on ${item.reviewed_at_date} by ${item.reviewer_name}`]
                  : undefined
                }
                additionalButton={{
                  buttonText: 'View details',
                  onClick: () => navigate(`/cpl-applicant/my-programs/${item.id}/details`)
                }}
                showFavourite={{
                  active: item.favourite,
                  toggleActive: () => handleUpdateFavourite(String(item.id))
                }}
              />
            </div>)
          : null
      }

      {
        Number(programsData?.total_pages) > 1
          ? <Pagination
            total={Number(programsData?.total_pages)}
            currentPage={currPage}
            onPageChange={setCurrPage}
          />
          : null
      }
    </div>
  </div>
}

export default UserPrograms;