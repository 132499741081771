import './applicant-program-details.scss';
import {useParams} from "react-router-dom";
import {useContext} from "react";
import {PortfolioContext} from "../../../../../hooks/Context/PortfolioContext";
import {useQueryPrograms} from "../../../../../api/admin/useQueryPrograms";
import Loading from "../../../../../components/UI/Loading/Loading";
import Notification from "../../../../../components/UI/Notification/Notification";
import {UserContext} from "../../../../../hooks/Context/UserContext";

const ApplicantProgramDetails = () => {
  const [user] = useContext(UserContext);
  const {program_id} = useParams();
  const [portfolio] = useContext(PortfolioContext);

  const {data: currProgram, isLoading} = useQueryPrograms({
    portfolio_id: portfolio.id,
    program_id: program_id,
    userIsSoft: user.isSoft
  });

  if (isLoading) {
    return <Loading loading/>
  }

  return <div className={'applicant-program-details'}>
    <Notification>
      {"Information provided is subject to change and may not reflect the most recent updates. For the most accurate and up-to-date information, please refer to "}
      <a href={currProgram.catalogue_url} target={'_blank'} rel={'noreferrer'}>
        {currProgram.catalogue_url}
      </a>.
    </Notification>

    <table className={'applicant-program-details-mobile-header'}>
      <td>
        <div className={'applicant-program-details-right-subtitle'}>
          Program lenght
        </div>
        <div className={'applicant-program-details-right-text'}>
          {currProgram.program_length ?? 'Not available'}
        </div>
      </td>

      <td>
        <div className={'applicant-program-details-right-subtitle'}>
          Campus location
        </div>
        <div className={'applicant-program-details-right-text'}>
          {currProgram.location ?? 'Not available'}
        </div>
      </td>
    </table>

    <table>
      <tr>
        <td className={'applicant-program-details-left'}>
          <div className={'applicant-program-details-left-subtitle'}>
            Description
          </div>
          <div className={'applicant-program-details-left-text'}>
            {currProgram.description}
          </div>

          <div className={'applicant-program-details-left-subtitle'}>
            Credits required:
          </div>
          <div
            className={'applicant-program-details-left-text'}
            dangerouslySetInnerHTML={{__html: currProgram.requirements_description ?? 'None.'}}
          />

          <div className={"applicant-program-details-left-divider"}/>
        </td>

        <td className={'applicant-program-details-right'}>
          <div className={'applicant-program-details-right-subtitle'}>
            Program lenght
          </div>
          <div className={'applicant-program-details-right-text'}>
            {currProgram.program_length ?? 'Not available'}
          </div>

          <div className={'applicant-program-details-right-subtitle'}>
            Campus location
          </div>
          <div className={'applicant-program-details-right-text'}>
            {currProgram.location ?? 'Not available'}
          </div>
        </td>
      </tr>
    </table>
  </div>
}

export default ApplicantProgramDetails;